<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Entire Offspring</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="littersHeaders"
              :items="dog.offspring"
              no-data-text="This dog does not have any offspring"
              :items-per-page="-1"
            >
              <template v-slot:item.identifier="{ item }">
                <router-link
                  :to="{
                    name: 'module-lhl-dogs-individual',
                    params: { dogId: item.id },
                  }"
                  >{{ item.identifier }}</router-link
                >
              </template>

              <template v-slot:item.sire="{ item }">
                <router-link
                  :to="{
                    name: 'module-lhl-dogs-individual',
                    params: { dogId: item.sire.id },
                  }"
                  >{{ item.sire.name }}</router-link
                >
              </template>
              <template v-slot:item.dam="{ item }">
                <router-link
                  :to="{
                    name: 'module-lhl-dogs-individual',
                    params: { dogId: item.dam.id },
                  }"
                  >{{ item.dam.name }}</router-link
                >
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      littersHeaders: [
        { text: "Name", value: "identifier" },
        { text: "Location", value: "location" },

        { text: "Size", value: "size.name" },
        { text: "Colour", value: "colour.name" },
      ],
    };
  },

  mounted() {
    if (this.dog.gender == "F") {
      this.littersHeaders.splice(2, 0, { text: "Sire", value: "sire" });
    } else {
      this.littersHeaders.splice(2, 0, { text: "Dam", value: "dam" });
    }
  },

  computed: {
    dog() {
      let dog = this.$store.getters["lhl/dogs/get"];

      return dog;
    },
  },
};
</script>
